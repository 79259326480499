import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Nav, Layout, Avatar, Dropdown } from '@douyinfe/semi-ui'
import { IconSemiLogo } from '@douyinfe/semi-icons';
import { IconAlertCircle, IconUserGroup, IconBox, IconHome, IconHistogram } from '@douyinfe/semi-icons';
import { NAVIGATION_SELECTION_KEY, getUser, setJWT } from '../utility';

function Framework(props) {
    const { Header, Sider, Content } = Layout;
    const [selection, setSelection] = useState(sessionStorage.getItem(NAVIGATION_SELECTION_KEY));
    const navigate = useNavigate();

    const items = [
        { itemKey: 'Home', text: '实时状态', icon: <IconHome size="large" />, to: "/" },
        { itemKey: 'Production', text: '产量统计', icon: <IconHistogram size="large" />, to: "/production" },
        { itemKey: 'Employee', text: '员工登记', icon: <IconUserGroup size="large" />, to: "/employee" },
        { itemKey: 'Fault', text: '故障记录', icon: <IconAlertCircle size="large" />, to: "/fault" },
        { itemKey: 'Product', text: '产品列表', icon: <IconBox size="large" />, to: "/product" },
        // { itemKey: 'Setting', text: '其他设置', icon: <IconSetting size="large" />, to: "/setting" },
    ]

    function onClickQuit() {
        setJWT("");
        navigate("/login");
    }


    function onSelect(obj) {
        sessionStorage.setItem(NAVIGATION_SELECTION_KEY, obj.itemKey);
        setSelection(obj.itemKey);
        items.forEach((item, index) => {
            if (item.itemKey === obj.itemKey) {
                navigate(item.to);
            }
        })
    }

    return (
        <Layout style={{ border: '1px solid var(--semi-color-border)', height: '100vh' }}>
            <Header style={{ backgroundColor: 'var(--semi-color-bg-1)' }}>
                <div>
                    <Nav mode="horizontal" defaultSelectedKeys={['Fault']}>
                        <Nav.Header>
                            <IconSemiLogo style={{ height: '36px', fontSize: 36 }} />
                        </Nav.Header>
                        <span
                            style={{
                                color: 'var(--semi-color-text-2)',
                            }}
                        >
                            <span
                                style={{
                                    marginRight: '24px',
                                    color: 'var(--semi-color-text-0)',
                                    fontWeight: '600',
                                }}
                            >
                                产线后台
                            </span>
                        </span>
                        <Nav.Footer>
                            <Dropdown
                                position="bottomRight"
                                render={
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={onClickQuit}>退出</Dropdown.Item>
                                    </Dropdown.Menu>
                                }
                            >
                                <Avatar size="small" color='light-blue' style={{ margin: 4 }}>{getUser()?.username.charAt(0)}</Avatar>
                            </Dropdown>
                        </Nav.Footer>
                    </Nav>
                </div>
            </Header>
            <Layout>
                <Sider style={{ backgroundColor: 'var(--semi-color-bg-1)' }}>
                    <Nav
                        style={{ maxWidth: 168, height: '100%' }}
                        defaultSelectedKeys={[selection]}
                        items={items}
                        onSelect={onSelect}
                        footer={{
                            collapseButton: true,
                        }}
                    />
                </Sider>
                <Content
                    style={{
                        padding: '20px',
                        backgroundColor: 'var(--semi-color-bg-0)',
                    }}
                >

                    <div>
                        {props.content}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
}

export default Framework;