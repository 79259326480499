import { Table, Toast, Space, Button, Spin, Modal, Form, Radio } from '@douyinfe/semi-ui'
import Framework from './Framework';
import React, { useEffect, useState, useMemo } from 'react';
import * as dateFns from 'date-fns';
import axios from 'axios';
import { getAuthConfig, tableStyle } from '../utility';


const columns = [
    {
        title: '姓名',
        dataIndex: 'name',
        render: (text, record, index) => {
            return (
                <div>
                    {text}
                </div>
            );
        }
    },
    {
        title: '工号',
        dataIndex: 'number',
        sorter: (a, b) => (a.number - b.number > 0 ? 1 : -1),
        render: text => `${text}`,
    },
    {
        title: '性别',
        dataIndex: 'gender',
        render: (text, record, index) => {
            return (
                <>
                    {text === 1 ? "男" : "女"}
                </>
            );
        },
    },
    {
        title: '年龄',
        dataIndex: 'birthday',
        sorter: (a, b) => (a.birthday - b.birthday > 0 ? 1 : -1),
        render: text => {
            if (text === null || text === undefined) {
                return "-";
            }
            let y = new Date().getFullYear() - new Date(text * 1000).getFullYear();
            return y + '';
        },
    },
    {
        title: '入职时间',
        dataIndex: 'createdAt',
        sorter: (a, b) => (a.createdAt - b.createdAt > 0 ? 1 : -1),
        render: value => {
            return dateFns.format(new Date(value * 1000), 'yyyy-MM-dd hh:mm');
        },
    },
    {
        title: '离职时间',
        dataIndex: 'resignedAt',
        sorter: (a, b) => (a.hasOwnProperty('resignedAt') ? 1 : -1),
        render: value => {
            return value === undefined ? "" : dateFns.format(new Date(value * 1000), 'yyyy-MM-dd hh:mm');
        },
    },
];

function ProductionPage() {
    const [formVisible, setFormVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formApi, setFormApi] = useState();
    const [dataSource, setDataSource] = useState([]);

    const scroll = useMemo(() => ({ y: 900 }), []);

    function query() {
        axios.get('/v1/employees', getAuthConfig()).then((response) => {
            setDataSource(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        query();
    }, []);

    function onClickAdd() {
        setLoading(false);
        setFormVisible(true);
    }

    function getFormApi(formApi) {
        setFormApi(formApi);
    }

    function handleOk() {
        formApi.validate()
            .then((values) => {
                setLoading(true);
                console.log(values);
                let params = Object.assign({}, values);
                delete params.age;

                let birthday = new Date();
                birthday.setFullYear(birthday.getFullYear() - values.age);
                birthday.setMonth(0);
                birthday.setDate(1);
                birthday.setHours(0);
                birthday.setMinutes(0);
                birthday.setSeconds(0);
                params.birthday = Math.floor(birthday.getTime() / 1000);
                params.number = parseInt(params.number, 10) + '';
                axios.post("/v1/employees", params, getAuthConfig()).then(response => {
                    if (response.status === 200) {
                      Toast.success("添加成功");
                      setFormVisible(false);
                      query();
                    }
                    setLoading(false);
                }).catch(error => {
                    setTimeout(() => { setLoading(false); }, 1000);
                })
            })
            .catch((errors) => {
                console.log(errors);
            });
    }

    function handleCancel() {
        setFormVisible(false);
    }

    return (
        <Framework content={
            <div>
                <Space align='baseline'>
                    <Button onClick={onClickAdd}>添加员工</Button>
                </Space>
                <br />
                <br />
                <Table style={tableStyle} columns={columns} dataSource={dataSource} scroll={scroll} />

                <Modal
                    title="添加员工"
                    visible={formVisible}
                    centered
                    onOk={handleOk}
                    onCancel={handleCancel}
                    maskClosable={false}
                    motion={false}
                >

                    <Spin spinning={loading}>
                        <Form labelPosition='left' getFormApi={getFormApi} >
                            <Form.Input field='name' label='姓名' style={{ width: '100%' }} placeholder='请输入姓名'
                                rules={[
                                    { type: 'string', message: 'type error' },
                                    { validator: (rule, value) => value !== undefined && value.trim().length > 0, message: '姓名不能为空' },
                                ]}
                            ></Form.Input>
                            <Form.Input field='age' label='年龄' style={{ width: '100%' }} placeholder='请输入年龄'
                                rules={[
                                    { type: 'string', message: 'type error' },
                                    { validator: (rule, value) => value !== undefined, message: '年龄不能为空' },
                                    { validator: (rule, value) => value !== undefined && /^\d+$/.test(value), message: '年龄仅允许数字' },
                                ]}
                            ></Form.Input>
                            <Form.RadioGroup field="gender" label='性别' rules={[
                                { type: 'integer' },
                                { required: true, message: '必须选择性别 ' }
                            ]}>
                                <Radio value={1}>男</Radio>
                                <Radio value={0}>女</Radio>
                            </Form.RadioGroup>
                            <Form.Input field='number' label='工号' style={{ width: '100%' }} placeholder='请输入工号'
                                rules={[
                                    { type: 'string', message: 'type error' },
                                    { validator: (rule, value) => value !== undefined, message: '工号不能为空' },
                                    { validator: (rule, value) => value !== undefined && value.length <= 10, message: '工号最长10个数字' },
                                    { validator: (rule, value) => value !== undefined && /^\d+$/.test(value), message: '工号仅允许数字' },
                                ]}
                            ></Form.Input>

                            {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Space ></Space>
                                <p>
                                    <Button htmlType='submit' type="primary" theme='solid' style={{ width: 120, }}>提交</Button>
                                </p>
                                <Space ></Space>
                            </div> */}
                        </Form>
                    </Spin>
                </Modal>


            </div>
        }>
        </Framework>
    );
}


export default ProductionPage;