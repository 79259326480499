import { Typography, Table, Spin, Select, Button, DatePicker, Space, Descriptions, Divider } from '@douyinfe/semi-ui'
import Framework from './Framework';
import React, { useEffect, useState, useMemo } from 'react';
import * as dateFns from 'date-fns';
import axios from 'axios';
import { getAuthConfig, tableStyle } from '../utility';

function ProductionPage() {
    const { Paragraph, Text, Numeral } = Typography;

    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState({
        deviceMap: {},
        productMap: {},
        employeeMap: {},
        productions: [],
    });

    const [currentEmployeeId, setCurrentEmployeeId] = useState();
    const [currentDeviceId, setCurrentDeviceId] = useState();
    const [currentDateRange, setCurrentDateRange] = useState()

    const columns = [
        {
            title: '设备',
            dataIndex: 'deviceId',
            render: (text) => {
                return (
                    <div>
                        {dataSource.deviceMap[text] === undefined ? '' : dataSource.deviceMap[text].name}
                    </div>
                );
            },
        },
        {
            title: '产品',
            dataIndex: 'productId',
            sorter: (a, b) => (a.productId - b.productId > 0 ? 1 : -1),
            render: text => { return dataSource.productMap[text] === undefined ? '' : dataSource.productMap[text].name },
        },
        {
            title: '产量',
            dataIndex: 'count',
            sorter: (a, b) => (a.count - b.count > 0 ? 1 : -1),
            render: text => `${text}`,
        },
        {
            title: '值班人员',
            dataIndex: 'employeeId',
            render: (text) => {
                return (
                    <div>
                        {dataSource.employeeMap[text] === undefined ? '' : dataSource.employeeMap[text].name}
                    </div>
                );
            },
        },
        {
            title: '开始时间',
            dataIndex: 'startedAt',
            sorter: (a, b) => (a.updateTime - b.updateTime > 0 ? 1 : -1),
            render: value => {
                return dateFns.format(new Date(value * 1000), 'yyyy-MM-dd hh:mm');
            },
        },
        {
            title: '结束时间',
            dataIndex: 'endedAt',
            sorter: (a, b) => (a.updateTime - b.updateTime > 0 ? 1 : -1),
            render: value => {
                return dateFns.format(new Date(value * 1000), 'yyyy-MM-dd hh:mm');
            },
        },
    ];

    const scroll = useMemo(() => ({ y: 900 }), []);

    function getEmployeeList() {
        let list = [];
        for (let e of Object.values(dataSource.employeeMap)) {
            list.push({ value: e.id, label: e.name, otherKey: e.id })
        };
        return list;
    }

    function getDeviceList() {
        let list = [];
        for (let e of Object.values(dataSource.deviceMap)) {
            list.push({ value: e.id, label: e.name, otherKey: e.id })
        };
        return list;
    }

    function query(employeeId, deviceId, startedAt, endedAt) {
        let queryParams = {}
        if (employeeId !== undefined) {
            queryParams['employeeId'] = employeeId;
        }
        if (deviceId !== undefined) {
            queryParams['deviceId'] = deviceId;
        }
        if (startedAt !== undefined && endedAt !== undefined) {
            queryParams['startedAt'] = startedAt;
            queryParams['endedAt'] = endedAt;
        }
        if (currentDateRange !== undefined
            && currentDateRange[0] !== undefined
            && currentDateRange[1] !== undefined) {
            queryParams['startedAt'] = Math.floor(currentDateRange[0].getTime() / 1000)
            queryParams['endedAt'] = Math.floor(currentDateRange[1].getTime() / 1000) + 24 * 60 * 60 //到这一天结束
        }
        setLoading(true);
        axios.get('/v1/productions?' + new URLSearchParams(queryParams), getAuthConfig()).then((response) => {
            let deviceMap = {};
            let employeeMap = {};
            let productMap = {};
            response.data.devices.map(device => {
                return deviceMap[device.id] = device;
            });
            response.data.employees.map(employee => {
                return employeeMap[employee.id] = employee;
            });
            response.data.products.map(product => {
                return productMap[product.id] = product;
            })

            console.log(deviceMap);
            setDataSource({
                productions: response.data.productions,
                deviceMap: deviceMap,
                productMap: productMap,
                employeeMap: employeeMap,
            });
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    function onClickQuery() {
        query(currentEmployeeId, currentDeviceId);
    }

    function getTotalProduction() {
        let count = 0;
        for (let e of dataSource.productions) {
            count += e.count;
        }
        return count;
    }

    function getTotalProductTime() {
        let count = 0;
        for (let e of dataSource.productions) {
            if (e.endedAt < e.startedAt) {
                continue;
            }
            count = count + (e.endedAt - e.startedAt) + 1;
        }
        return (count / 60.0 / 60.0).toFixed(1);
    }

    useEffect(() => {
        query();
    }, []);

    return (
        <Framework content={
            <Spin spinning={loading}>

                <Space align="baseline">
                    <Select placeholder="选择值班人员"
                        showClear={true} style={{ width: 180 }}
                        optionList={getEmployeeList()}
                        value={currentEmployeeId}
                        onChange={setCurrentEmployeeId}></Select>
                    <Select placeholder="选择设备" showClear={true}
                        style={{ width: 180 }}
                        optionList={getDeviceList()}
                        value={currentDeviceId}
                        onChange={setCurrentDeviceId}></Select>
                    <DatePicker format="yyyy年MM月dd日" type="dateRange" density="compact" onChange={setCurrentDateRange}></DatePicker>
                    <Button onClick={onClickQuery}>查询</Button>

                </Space>

                <br />
                <br />
                <div style={tableStyle}>

                <Descriptions
                    style={{
                        paddingLeft: '14px',
                        paddingTop: '10px',
                        paddingBottom: '14px'
                    }}
                    row
                    size='large'>
                    <Descriptions.Item itemKey="累计产量" style={{color: 'green'}}>
                            {getTotalProduction()}
                    </Descriptions.Item>
                    <Descriptions.Item itemKey="累计生产时间">
                        {getTotalProductTime()} <Text>小时</Text>
                    </Descriptions.Item>
                </Descriptions>

                <Divider margin='14px'></Divider>

                <Table columns={columns} dataSource={dataSource.productions} scroll={scroll} rowKey={"id"} />

                </div>
            </Spin>
        }>
        </Framework>
    );
}


export default ProductionPage;