
import { createBrowserHistory } from 'history'
// import { createBrowserRouter } from 'react-router-dom';

const NAVIGATION_SELECTION_KEY = "navigationSelection"


function setJWT(jwt) {
  localStorage.setItem("jwt", jwt);
}

function getJWT() {
  return localStorage.getItem("jwt");
}

function setUser(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

function getUser() {
  let str = localStorage.getItem("user")
  if (str === undefined) {
    return {};
  }
  return JSON.parse(str);
}

function isLoggedIn() {
  let value = localStorage.getItem("jwt")
  if (value === null) {
    return false;
  }
  if (value === "") {
    return false;
  }
  return true;
}

function getAuthConfig() {
  return {
    headers: {
      Authorization: 'Bearer ' + getJWT()
    }
  }
}

function minutesPass(timestamp) {
  return (Math.floor(Date.now() / 1000) - timestamp) / 60
}

function relativeTime(timestamp) {
  let secs = Math.floor(Date.now() / 1000) - timestamp;
  let minutes = Math.floor(secs / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  if (minutes <= 1) {
    return "刚刚";
  }
  if (hours < 1) {
    return minutes + '分钟前';
  }
  if (hours < 24) {
    return hours + '小时' + (minutes % 60) + '分之前';
  }
  if (days < 31) {
    return days + '天前';
  }
  return timestampToTime(timestamp);
}

function timestampToTime(timestamp) {
  const date = new Date(timestamp * 1000);
  const Y = date.getFullYear();
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
  const D = date.getDate();
  const h = date.getHours();
  const m = date.getMinutes();
  const s = date.getSeconds();
  return Y + "-" + M + "-" + D + " " + h + ":" + m + ":" + s
  // return Y+M+D+h+m+s; 
}

const tableStyle = {
  borderRadius: '10px',
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingTop: '14px',
  border: '1px solid var(--semi-color-border)',
}


const history = createBrowserHistory()
// const navigateRef = createBrowserRouter()

export {
  setJWT, isLoggedIn, getAuthConfig, timestampToTime, history,
  minutesPass, relativeTime, tableStyle, NAVIGATION_SELECTION_KEY,
  setUser, getUser
}
