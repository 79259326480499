import React, { useEffect, useState } from 'react';
import { List, Descriptions, Badge, Spin } from '@douyinfe/semi-ui'
import Framework from './Framework';
import axios from 'axios';
import { getAuthConfig, minutesPass, relativeTime } from '../utility';

function DeviceTable() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const style = {
    border: '1px solid var(--semi-color-border)',
    backgroundColor: 'var(--semi-color-bg-2)',
    borderRadius: '3px',
    paddingLeft: '15px',
    margin: '8px 2px',
  };

  useEffect(() => {
    const query = () => {
      //请求state数据
      axios.get('/v1/states', getAuthConfig()).then((response) => {
        setLoading(false);
        setData(response.data);
      }).catch((error) => {
        console.log(error);
      });
    }

    let timerId = setInterval(() => {
      query();
    }, 30000);

    setLoading(true);
    query();

    return () => {
      //unmount
      clearInterval(timerId);
    }
  }, []);


  function getData(item) {
    let list = [
      { key: '设备状态', value: item.state },
      { key: '值班人员', value: item.employee === undefined ? "-" : item.employee.name },
      { key: '更新时间', value: item.updatedAt === undefined ? "-" : relativeTime(item.updatedAt) },
    ];
    if (item.device.type !== undefined) {
      list.push({ key: "设备型号", value: item.device.type })
    }
    if (item.extra !== undefined) {
      for (let key in item.extra) {
        list.push({ key: key, value: item.extra[key] });
      };
    }
    return list;
  }

  function isOnline(timestamp) {
    if (timestamp === undefined) {
      return false;
    }
    return minutesPass(timestamp) < 3;
  }

  return (
    <Spin spinning={loading}>
      <List
        grid={{
          gutter: 12,
          xs: 0,
          sm: 0,
          md: 12,
          lg: 8,
          xl: 8,
          xxl: 6,
        }}
        dataSource={data}
        renderItem={item => (
          <List.Item style={style}>
            <div>
              <h3 style={{ color: 'var(--semi-color-text-0)', fontWeight: 500 }}>
                <span><Badge count={isOnline(item.updatedAt) ? "在线" : "离线"} type={isOnline(item?.updatedAt) ? "success" : "danger"} /> {item.device.name}</span>
              </h3>
              <Descriptions
                align="center"
                size="small"
                data={getData(item)}
              />
            </div>
          </List.Item>
        )}
      />
    </Spin>
  );
}

function DevicePage() {

  return (
    <Framework content={
      <DeviceTable></DeviceTable>
    }>
    </Framework>
  );
}


export default DevicePage;