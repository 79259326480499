import React from 'react';
import { Layout, Button, Form, Toast, Spin } from '@douyinfe/semi-ui';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconMailStroked1, IconKey } from '@douyinfe/semi-icons';
import * as utility from '../utility';
import { NAVIGATION_SELECTION_KEY } from '../utility';
import axios from 'axios'

function LoginPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    setLoading(true);
    axios.post("/v1/users/signin", {
      username: values.account,
      password: values.password
    }).then(response => {
      if (response.status !== 200) {
        Toast.error("登录失败:" + response.data);
        return;
      }
      utility.setJWT(response.data);
      axios.get("/v1/users/me", utility.getAuthConfig()).then(response => {
        utility.setUser(response.data);
        sessionStorage.setItem(NAVIGATION_SELECTION_KEY, "Home");
        Toast.success("登录成功");
        setLoading(false);
        navigate("/");
      }).catch(error => {
        Toast.error("登录失败：" + error.message);
        setLoading(false);
      });
    }).catch(error => {
      console.log(error);

      Toast.error("无法登录：" + error.message);
      setTimeout(() => { setLoading(false); }, 1000);
    })
    // Toast.info("handle submit " + JSON.stringify(values));
  };

  return (<Layout style={{ display: 'grid', height: '100vh' }}>
    <Spin spinning={loading} style={{ alignSelf: 'center', justifySelf: 'center' }}>
      <Form size="large" onSubmit={handleSubmit} style={{
        border: '1px solid var(--semi-color-border)', 
        padding: '44px', 
        width: '415px',
      }}>
        <Form.Label size='medium'>
          产线后台
        </Form.Label>
        <Form.Input prefix={<IconMailStroked1 />} field='account' noLabel={true} style={{ width: '100%' }} placeholder='请输入邮箱账号'
          rules={[
            { type: 'string', message: 'type error' },
            { validator: (rule, value) => value !== undefined, message: '邮箱不能为空' },
          ]}
        ></Form.Input>

        <Form.Input prefix={<IconKey />} field='password' noLabel={true} style={{ width: '100%' }} placeholder='请输入密码'
          mode='password'
          rules={[
            { type: 'string', message: 'type error' },
            { validator: (rule, value) => value !== undefined, message: '密码不能为空' },
          ]}
        ></Form.Input>
        <Form.Checkbox initValue={utility.isLoggedIn()} field='agree' noLabel >记住账号</Form.Checkbox>
        <Button htmlType='submit' theme='solid' type="primary" style={{ width: '100%' }}>登录</Button>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p>
            {/* <span></span><Button theme='borderless' style={{ color: 'var(--semi-color-primary)', marginLeft: 0, cursor: 'pointer' }}>注册</Button> */}
          </p>
        </div>
      </Form>
    </Spin>

  </Layout>);
}

export default LoginPage;