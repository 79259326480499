import { Table } from '@douyinfe/semi-ui'
import Framework from './Framework';
import React, { useEffect, useState, useMemo } from 'react';
import * as dateFns from 'date-fns';
import axios from 'axios';
import { getAuthConfig, tableStyle } from '../utility';

const columns = [
    {
        title: '产品名称',
        dataIndex: 'name',
        render: (text, record, index) => {
            return (
                <div>
                    {text}
                </div>
            );
        }
    },
    {
        title: '创建时间',
        dataIndex: 'createdAt',
        sorter: (a, b) => (a.createdAt - b.createdAt > 0 ? 1 : -1),
        render: value => {
            return dateFns.format(new Date(value * 1000), 'yyyy-MM-dd hh:mm');
        },
    }
];

function ProductionPage() {
    const [dataSource, setDataSource] = useState([]);

    const scroll = useMemo(() => ({ y: 900 }), []);

    useEffect(() => {
        // const data = getData();
        axios.get('/v1/products', getAuthConfig()).then((response) => {
            setDataSource(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    return (
        <Framework content={
            <div>
                <Table style={tableStyle} columns={columns} dataSource={dataSource} scroll={scroll} />
            </div>
        }>
        </Framework>
    );
}


export default ProductionPage;