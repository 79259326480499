import React, { useEffect, useState, useMemo } from 'react';
import { Space, Spin, Select, DatePicker, Button, Table } from '@douyinfe/semi-ui'
import { } from '@douyinfe/semi-icons';
import Framework from './Framework';
import axios from 'axios';
import * as dateFns from 'date-fns';
import { getAuthConfig, tableStyle } from '../utility';

function FaultPage() {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState({
    deviceMap: {},
    employeeMap: {},
    faults: [],
  });

  const [currentEmployeeId, setCurrentEmployeeId] = useState();
  const [currentDeviceId, setCurrentDeviceId] = useState();
  const [currentDateRange, setCurrentDateRange] = useState()


  const columns = [
    {
      title: '设备',
      dataIndex: 'deviceId',
      render: (text, record, index) => {
        return (
          <div>
            {dataSource.deviceMap === undefined ? '' : dataSource.deviceMap[text].name}
          </div>
        );
      }
    },
    {
      title: '故障类型',
      dataIndex: 'type',
      render: text => text,
    },
    {
      title: '详细内容',
      dataIndex: 'extra',
      render: text => text,
    },
    {
      title: '值班人员',
      dataIndex: 'employeeId',
      render: (text, record, index) => {
        return (
          <div>
            {dataSource.employeeMap === undefined ? '' : dataSource.employeeMap[text].name}
          </div>
        );
      },
    },
    {
      title: '开始时间',
      dataIndex: 'startedAt',
      sorter: (a, b) => (a.startedAt - b.startedAt > 0 ? 1 : -1),
      render: value => {
        return dateFns.format(new Date(value * 1000), 'yyyy-MM-dd hh:mm');
      },
    },
    {
      title: '结束时间',
      dataIndex: 'endedAt',
      sorter: (a, b) => (a.endedAt - b.endedAt > 0 ? 1 : -1),
      render: value => {
        return dateFns.format(new Date(value * 1000), 'yyyy-MM-dd hh:mm');
      },
    },
    {
      title: '持续时间',
      dataIndex: 'duration',
      sorter: (a, b) => ((a.endedAt - a.startedAt) - (b.endedAt - b.startedAt) > 0 ? 1 : -1),
      render: (value, record, index) => {
        let secs = record.endedAt - record.startedAt;
        if (secs <= 0) {
          return "";
        }
        let hour = secs / 60 / 60;
        let min = secs / 60;
        let sec = secs % 60;
        return hour + "小时" + min + "分钟" + sec + "秒";
      },
    }
  ];

  const scroll = useMemo(() => ({ y: 900 }), []);

  function getEmployeeList() {
    let list = [];
    for (let e of Object.values(dataSource.employeeMap)) {
      list.push({ value: e.id, label: e.name, otherKey: e.id })
    };
    return list;
  }

  function getDeviceList() {
    let list = [];
    for (let e of Object.values(dataSource.deviceMap)) {
      list.push({ value: e.id, label: e.name, otherKey: e.id })
    };
    return list;
  }

  function query(employeeId, deviceId, startedAt, endedAt) {
    let queryParams = {}
    if (employeeId !== undefined) {
      queryParams['employeeId'] = employeeId;
    }
    if (deviceId !== undefined) {
      queryParams['deviceId'] = deviceId;
    }
    if (startedAt !== undefined && endedAt !== undefined) {
      queryParams['startedAt'] = startedAt;
      queryParams['endedAt'] = endedAt;
    }
    if (currentDateRange !== undefined
      && currentDateRange[0] !== undefined
      && currentDateRange[1] !== undefined) {
      queryParams['startedAt'] = Math.floor(currentDateRange[0].getTime() / 1000)
      queryParams['endedAt'] = Math.floor(currentDateRange[1].getTime() / 1000) + 24 * 60 * 60 //到这一天结束
    }
    setLoading(true);
    axios.get('/v1/faults?' + new URLSearchParams(queryParams), getAuthConfig()).then((response) => {
      let deviceMap = {};
      let employeeMap = {};
      response.data.devices.map(device => {
        return deviceMap[device.id] = device;
      });
      response.data.employees.map(employee => {
        return employeeMap[employee.id] = employee;
      });

      console.log(deviceMap);
      setDataSource({
        faults: response.data.faults,
        deviceMap: deviceMap,
        employeeMap: employeeMap,
      });
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }

  function onClickQuery() {
    query(currentEmployeeId, currentDeviceId);
  }


  useEffect(() => {
    query();
  }, []);

  return (
    <Framework content={
      <Spin spinning={loading}>

        <Space align="baseline">
          <Select placeholder="选择值班人员"
            showClear={true} style={{ width: 180 }}
            optionList={getEmployeeList()}
            value={currentEmployeeId}
            onChange={setCurrentEmployeeId}></Select>
          <Select placeholder="选择设备" showClear={true}
            style={{ width: 180 }}
            optionList={getDeviceList()}
            value={currentDeviceId}
            onChange={setCurrentDeviceId}></Select>

          <DatePicker type="dateRange" density="compact" onChange={setCurrentDateRange}></DatePicker>
          <Button onClick={onClickQuery}>查询</Button>

        </Space>
        <br />
        <br />

        <Table style={tableStyle}
          format="yyyy年MM月dd日" columns={columns} dataSource={dataSource.faults} scroll={scroll} rowKey="id" />

      </Spin>
    }>
    </Framework>
  );


}


export default FaultPage;